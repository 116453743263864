import {Box, BoxRadius, BoxSpacing, BoxShadow, FlexContent, FlexContentDirection, FlexContentAlignment, FlexContentJustify, ButtonSize, Action, Button} from "@sirdata/ui-lib";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile, pathContainerCreate} from "../../../utils/constants";

const ContainerFirstConfigurationBox = () => {
    const history = useHistory();
    const {t: textContainer} = useTranslation(TranslationPortalFile.CONTAINER);

    return (
        <Box
            radius={BoxRadius.MD}
            spacing={BoxSpacing.LARGE}
            shadow={BoxShadow.BLACK_MEDIUM}
        >
            <FlexContent
                direction={FlexContentDirection.ROW}
                alignment={FlexContentAlignment.CENTER}
                justify={FlexContentJustify.SPACE_BETWEEN}
            >
                <span className="h2">{textContainer("empty_content.message")}</span>
                <Button
                    size={ButtonSize.BIG}
                    icon={Action.ADD.icon}
                    onClick={() => history.push(pathContainerCreate)}
                />
            </FlexContent>
        </Box>
    );
};

export default ContainerFirstConfigurationBox;
