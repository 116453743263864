import React, {FunctionComponent, useEffect, useState} from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {goToLogin} from "../../common/utils/helper";
import {PORTAL} from "../../utils/Portal";
import {Authorization} from "../../api/model/account/Authorization";
import {LoggedAccount} from "../../common/api/interface/LoggedAccount";
import {pathAccess} from "../../common/utils/constants";

interface PrivateRouteProps extends RouteProps {
    component: FunctionComponent;
    path: string;
    requiredAuthorization?: Authorization;
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({path, component, requiredAuthorization, ...rest}) => {
    const [account, setAccount] = useState<LoggedAccount | undefined>();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const isLogged = await session.isLogged();
                if (!isLogged) {
                    goToLogin();
                    return;
                }

                const account = await session.getAccount();
                setAccount(account);
            } catch (e) {
            } finally {
                setLoaded(true);
            }
        })();
    }, []);

    if (!loaded || !account) return <></>;

    if (!account.hasAccess(PORTAL.origin.name)) {
        return <Redirect to={pathAccess}/>;
    }

    if (requiredAuthorization && !account.hasAuthorization(requiredAuthorization)) {
        return <Redirect to={PORTAL.defaultPath}/>;
    }

    return <Route {...rest} exact path={path} component={component}/>;
};

export default PrivateRoute;
