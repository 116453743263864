import {
    Action,
    AlertItem,
    AlertSeverity,
    ButtonAdd,
    ButtonSize,
    ButtonStyle,
    Color,
    ContentBlock,
    LayoutRows,
    Loadable,
    MainAlert,
    MainContentPageHeader,
    ModalConfirmMessage,
    SearchError,
    SearchToolbar,
    Table,
    TableColumnStyle,
    TranslationLibFile
} from "@sirdata/ui-lib";
import {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {Container} from "../../api/model/container/Container";
import {ContainerErrorCode} from "../../api/model/container/ContainerErrorCode";
import {ContainerField} from "../../api/model/container/ContainerField";
import {ContainerFirstConfigurationBox, ContainerTableRow} from "../../component/snippet";
import {ContainerHostDetailsField} from "../../api/model/container/ContainerHostDetailsField";
import {ContainerSearchQuery} from "../../api/model/container/search/ContainerSearchQuery";
import {ContainerSearchResult} from "../../api/model/container/search/ContainerSearchResult";
import {ContainerStatus} from "../../api/model/container/ContainerStatus";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {session} from "../../api/ApiSession";
import {TranslationPortalFile, pathContainerCreate} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";

enum ContainerListModal {
    DELETE_CONTAINER
}

const MAX_ROWS_PER_PAGE = 20;

function ContainerList() {
    const history = useHistory();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textContainer} = useTranslation(TranslationPortalFile.CONTAINER);
    const [message, setMessage] = useState<AlertItem>();
    const [isLoading, setLoading] = useState(true);

    const [containers, setContainers] = useState<Container[]>([]);
    const [containerSearchResult, setContainerSearchResult] = useState<ContainerSearchResult>(new ContainerSearchResult());
    const [query, setQuery] = useState<string>("");

    const [activeContainer, setActiveContainer] = useState<Container>();
    const [activeModal, setActiveModal] = useState<ContainerListModal>();

    const loadContainers = useCallback(async () => {
        try {
            setLoading(true);
            setMessage(undefined);

            const newContainers = await session.restContainer.search(new ContainerSearchQuery());
            setContainerSearchResult(newContainers);
            setContainers(newContainers.elements);
        } catch (e) {
            console.error("Failed to load containers list", e);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        loadContainers();
    }, [loadContainers]);

    useEffect(() => {
        setContainers(containerSearchResult.elements.filter((it) => it.name.toLowerCase().includes(query.toLowerCase())));
    }, [query, containerSearchResult.elements]);

    const handleAddContainer = () => history.push(pathContainerCreate);

    const handleUpdateStatus = async (container: Container) => {
        try {
            await session.restContainer.updateStatus(container.id, container.status === ContainerStatus.ACTIVE ? ContainerStatus.INACTIVE : ContainerStatus.ACTIVE);
            loadContainers();
        } catch (e) {
            UIEventManager.alert({
                text: textContainer(`error.${e instanceof ErrorResponse ? e.code : ContainerErrorCode.FAIL_UPDATE_CONF_STATUS}`),
                severity: AlertSeverity.DANGER,
                isClosable: true
            });
        }
    };

    const handleDeleteContainer = async () => {
        if (!activeContainer) return;
        try {
            await session.restContainer.delete(activeContainer.id);
            loadContainers();
        } catch (e) {
            UIEventManager.alert({
                text: textContainer(`error.${e instanceof ErrorResponse ? e.code : ContainerErrorCode.FAIL_DELETE_CONF}`),
                severity: AlertSeverity.DANGER,
                isClosable: true
            });
        } finally {
            setActiveContainer(undefined);
            closeModal();
        }
    };

    const closeModal = () => {
        setActiveModal(undefined);
        setActiveContainer(undefined);
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <MainContentPageHeader title={textContainer("title")} icon={{name: "dns", colorIcon: Color.CYAN, outlined: true}}/>
                <LayoutRows>
                    <MainAlert content={message}/>
                    <Loadable loading={isLoading}>
                        {!!containerSearchResult.total_elements ? (
                            <ContentBlock>
                                <SearchToolbar
                                    searchBar={{placeholder: textContainer("search_for_container"), value: query, onChange: setQuery}}
                                    actions={[<ButtonAdd key={0} size={ButtonSize.MEDIUM} onClick={handleAddContainer}/>]}
                                />
                                {!!containers.length ? (
                                    <Table
                                        columns={[
                                            {label: textContainer(`field.${ContainerField.STATUS}`), width: 10},
                                            {label: textContainer(`field.${ContainerField.ID}`), width: 10},
                                            {label: textContainer(`field.${ContainerField.NAME}`), width: 30, styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                            {label: textContainer(`field.${ContainerField.HOST_DETAILS}.${ContainerHostDetailsField.FQDN}`), width: 40},
                                            {label: undefined, width: 10}
                                        ]}
                                        pagination={MAX_ROWS_PER_PAGE}
                                    >
                                        {containers.map((container) =>
                                            <ContainerTableRow
                                                key={container.id}
                                                container={container}
                                                actions={{
                                                    onDelete: () => {
                                                        setActiveContainer(container);
                                                        setActiveModal(ContainerListModal.DELETE_CONTAINER);
                                                    },
                                                    onEdit: () => {
                                                        handleUpdateStatus(container);
                                                    }
                                                }}
                                            />
                                        )}
                                    </Table>
                                ): (
                                    <SearchError query={query}/>
                                )}
                            </ContentBlock>
                        ) : (
                            <ContainerFirstConfigurationBox/>
                        )}
                    </Loadable>
                </LayoutRows>
                <ModalConfirmMessage
                    active={activeModal === ContainerListModal.DELETE_CONTAINER}
                    message={textContainer("modal.delete.container")}
                    cancel={{onClick: closeModal, style: ButtonStyle.PRIMARY_MIDNIGHT, children: textCommon(Action.CANCEL.labelKey)}}
                    confirm={{onClick: handleDeleteContainer, style: ButtonStyle.DEFAULT_MIDNIGHT, children: textCommon(Action.DELETE.labelKey)}}
                />
            </MainContent>
        </Wrapper>
    );
}

export default ContainerList;
