import {Action, ActionsMenu, Color, IconEdit, StatusLabel, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {FunctionComponent} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {Container} from "../../../api/model/container/Container";
import {ContainerStatus} from "../../../api/model/container/ContainerStatus";
import {ContainerTableRowHostname} from "..";
import {TranslationPortalFile} from "../../../utils/constants";

export type ContainerTableRowActions = {
    onDelete: () => void;
    onEdit: () => void;
}

type ContainerTableRowProps = {
    container: Container;
    actions: ContainerTableRowActions;
}

const ContainerTableRow: FunctionComponent<ContainerTableRowProps> = ({container, actions}) => {
    const history = useHistory();
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textContainer} = useTranslation(TranslationPortalFile.CONTAINER);

    return (
        <TableRow>
            <TableColumn styles={TableColumnStyle.NOWRAP}>
                <StatusLabel
                    label={textContainer(`status.${container.status}`)}
                    icon={{name: "circle", colorIcon: container.status === ContainerStatus.ACTIVE ? Color.GREEN : Color.ORANGE}}
                />
            </TableColumn>
            <TableColumn>{container.id}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{container.name}</TableColumn>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}><ContainerTableRowHostname container={container}/></TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <IconEdit onClick={() => history.push(container.getDetailsRoute())}/>
                <ActionsMenu
                    iconTooltip={{icon: Action.MORE_ACTIONS.icon, text: textCommon(Action.MORE_ACTIONS.labelKey)}}
                    items={[
                        {label: textCommon(container.status === ContainerStatus.ACTIVE ? Action.DEACTIVATE.labelKey : Action.ACTIVATE.labelKey), onClick: actions.onEdit},
                        {label: textCommon(Action.DELETE.labelKey), onClick: actions.onDelete, separator: true, critical: true}
                    ]}
                />
            </TableActionColumn>
        </TableRow>
    );
};

export default ContainerTableRow;
