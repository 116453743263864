import {Action, Box, BoxProps, Button, ButtonLink, ButtonLinkStyle, ButtonSize, ButtonStyle, Color, ContentBlock, ContentBlockAction, FlexContent, FlexContentDirection, FlexContentLayout, FlexContentMobileDirection, FlexContentSpacing, LayoutRows, Paragraph, StatusLabel, Table, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {ContainerField} from "../api/model/container/ContainerField";
import {ContainerFirstConfigurationBox, ContainerTableRowHostname} from "../component/snippet";
import {ContainerHostDetailsField} from "../api/model/container/ContainerHostDetailsField";
import {ContainerSearchQuery} from "../api/model/container/search/ContainerSearchQuery";
import {ContainerSearchResult} from "../api/model/container/search/ContainerSearchResult";
import {ContainerStatus} from "../api/model/container/ContainerStatus";
import {Formatter} from "../common/utils/Formatter";
import {Locale} from "../common/utils/Locale";
import {MainContent, Wrapper} from "../common/component/widget";
import {MainHeader, NotificationCarousel} from "../common/component/snippet";
import {ModalContactUiEvent} from "../common/component/modal/ModalContact";
import {News} from "../common/api/model/portal/News";
import {session} from "../api/ApiSession";
import {TranslationPortalFile, pathContainerCreate, pathContainerList} from "../utils/constants";
import {UIEventManager} from "../common/utils/UIEventManager";

const CONTAINER_MAX_ROWS = 6;

function Home() {
    const history = useHistory();
    const {t: textHome} = useTranslation(TranslationPortalFile.HOME);
    const {t: textContainer} = useTranslation(TranslationPortalFile.CONTAINER);

    const [newses, setNewses] = useState<News[]>([]);
    const [containerSearchResult, setContainerSearchResult] = useState<ContainerSearchResult>(new ContainerSearchResult());

    useEffect(() => {
        (async () => {
            try {
                const newses = await session.getNewses();
                setNewses(newses);
            } catch (e) {
                console.error("Failed to load newses", e);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const containerSearchResult = await session.restContainer.search(new ContainerSearchQuery());
                setContainerSearchResult(containerSearchResult);
            } catch (e) {
                console.error("Failed to load container list", e);
            }
        })();
    }, []);

    const goToContainerList = () => history.push(pathContainerList);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <LayoutRows cssClass={"home"}>
                    <FlexContent direction={FlexContentDirection.ROW} layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT} spacing={FlexContentSpacing.LARGE_PLUS} mobileDirection={FlexContentMobileDirection.COLUMN}>
                        <LayoutRows>
                            <NotificationCarousel cssClass="home__notification-carousel"/>
                            {containerSearchResult.total_elements ? (
                                <ContentBlock
                                    header={{
                                        title: {label: textHome("container_list").toUpperCase(), icon: {name: "dns"}},
                                        actions: [
                                            <ContentBlockAction key="see-container-list" action={Action.EDIT} onClick={goToContainerList}/>,
                                            <ContentBlockAction key="add-container" action={Action.ADD} onClick={() => history.push(pathContainerCreate)}/>
                                        ]
                                    }}
                                >
                                    <Table
                                        columns={[
                                            {label: textContainer(`field.${ContainerField.STATUS}`), width: 20},
                                            {label: textContainer(`field.${ContainerField.NAME}`), width: 30},
                                            {label: textContainer(`field.${ContainerField.HOST_DETAILS}.${ContainerHostDetailsField.FQDN}`), width: 50}
                                        ]}
                                    >
                                        {containerSearchResult.elements.slice(0, CONTAINER_MAX_ROWS).map((container) =>
                                            <TableRow key={container.id} onClick={() => history.push(container.getDetailsRoute())}>
                                                <TableColumn styles={TableColumnStyle.NOWRAP}>
                                                    <StatusLabel
                                                        label={textContainer(`status.${container.status}`)}
                                                        icon={{name: "circle", colorIcon: container.status === ContainerStatus.ACTIVE ? Color.GREEN : Color.ORANGE}}
                                                    />
                                                </TableColumn>
                                                <TableColumn>{container.name}</TableColumn>
                                                <TableColumn><ContainerTableRowHostname container={container}/></TableColumn>
                                            </TableRow>
                                        )}
                                        {containerSearchResult.total_elements > CONTAINER_MAX_ROWS &&
                                            <TableRow key={"container-list-row-see-more"} onClick={goToContainerList}>
                                                <TableColumn styles={TableColumnStyle.ALIGN_CENTER} colSpan={3}>{"..."}</TableColumn>
                                            </TableRow>
                                        }
                                    </Table>
                                </ContentBlock>
                            ) : (
                                <ContainerFirstConfigurationBox/>
                            )}
                        </LayoutRows>
                        <LayoutRows>
                            {newses.length > 0 &&
                                <ContentBlock
                                    header={{title: {label: textHome("latest_newses.title").toUpperCase(), icon: {name: "article"}}}}
                                    footer={{link: "https://news.sirdata.com/tag/cmp/", label: textHome("latest_newses.see_all_newses"), target: "_blank"}}
                                >
                                    <Box {...BoxProps.SECTION_BLOCK_WITH_SHADOW} cssClass={"home__news"}>
                                        {newses.filter(({language}) => Locale.getCurrentLocale() === language).slice(0, 5).map(({title, url, pubDate}) =>
                                            <div key={title} className="news__item">
                                                <span className="news__item__date">{Formatter.formatDate(pubDate)}</span>
                                                <ButtonLink
                                                    cssClass="news__item__title"
                                                    style={ButtonLinkStyle.MIDNIGHT_LIGHT}
                                                    onClick={() => window.open(url, "_blank")}
                                                    reverseUnderline
                                                >
                                                    {title}
                                                </ButtonLink>
                                            </div>
                                        )}
                                    </Box>
                                </ContentBlock>
                            }
                            <ContentBlock header={{title: {label: textHome("support.title").toUpperCase(), icon: {name: "support"}}}}>
                                <Box cssClass={"home__support"} {...BoxProps.SECTION_BLOCK_WITH_SHADOW}>
                                    <div className="support__picture">
                                        <img src="/common/images/support/superdata.png" alt="Superdata"/>
                                    </div>
                                    <Paragraph withSpacing>{textHome("support.support_text")}</Paragraph>
                                    <Button size={ButtonSize.MEDIUM} style={ButtonStyle.PRIMARY_GREEN} onClick={() => UIEventManager.emit(ModalContactUiEvent, {})} icon={{name: "chat_bubble", outlined: true}}>
                                        {textHome("support.support_btn")}
                                    </Button>
                                </Box>
                            </ContentBlock>
                        </LayoutRows>
                    </FlexContent>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default Home;
