import {ApiModel} from "../../../common/api/model/ApiModel";

export const DEFAULT_GTM_DATALAYER = "dataLayer";

export class ContainerProperties extends ApiModel {
    processing_overlay: boolean = false;
    gtm_path: string = "";
    gtm_path_active: boolean = false;
    gtm_id: string = "";
    gtm_datalayer: string = "";
    gtm_order_datalayer: boolean = true;
    ga4_path: string = "";
    ga4_path_active: boolean = false;
    ga4_collect_path: string = "";
    ga4_id: string = "";
    helper_active: boolean = false;
    helper_partner_id: number | null = null;
    helper_config_id: number | null = null;
    helper_embed: boolean = false;
    avoid_cookies: boolean = true;
    avoid_request_cookies: boolean = false;
    cmp_embed: boolean = false;
    cmp_partner_id: number | null = null;
    cmp_config_id: string = "";
    cookieless_id_1p: boolean = false;
    cookieless_id_3p: boolean = false;
    apply_transparency: boolean = true;
    extend_cookies: boolean = false;
    service_url: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            processing_overlay: this.processing_overlay,
            gtm_path: this.gtm_path,
            gtm_path_active: this.gtm_path_active,
            gtm_id: this.gtm_id,
            gtm_datalayer: this.gtm_datalayer,
            gtm_order_datalayer: this.gtm_order_datalayer,
            ga4_path: this.ga4_path,
            ga4_path_active: this.ga4_path_active,
            ga4_collect_path: this.ga4_collect_path,
            ga4_id: this.ga4_id,
            helper_active: this.helper_active,
            helper_partner_id: this.helper_partner_id,
            helper_config_id: this.helper_config_id,
            helper_embed: this.helper_embed,
            avoid_cookies: this.avoid_cookies,
            avoid_request_cookies: this.avoid_request_cookies,
            cmp_embed: this.cmp_embed,
            cmp_partner_id: this.cmp_partner_id,
            cmp_config_id: this.cmp_config_id,
            cookieless_id_1p: this.cookieless_id_1p,
            cookieless_id_3p: this.cookieless_id_3p,
            apply_transparency: this.apply_transparency,
            extend_cookies: this.extend_cookies,
            service_url: this.service_url
        };
    }
}
