import React, {Suspense} from "react";
import {createBrowserHistory} from "history";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import {ScrollToTop} from "@sirdata/ui-lib";

import PrivateRoute from "./component/guard/PrivateRoute";
import {Access, ContainerCreate, ContainerDetails, ContainerList, Home, LegalNotice, Login, Sso, TermsAndConditions} from "./screen/index";
import {Error403, Error404, Error500} from "./common/screen/index";
import {pathContainerCreate, pathContainerDetails, pathContainerList, pathTermsAndConditions} from "./utils/constants";
import {pathAccess, pathError403, pathError404, pathError500, pathHome, pathLegalNotice, pathLogin, pathSso} from "./common/utils/constants";
import {session} from "./api/ApiSession";
import {SirdataApiEvent} from "./common/api/CommonApiClient";

const history = createBrowserHistory();

function App() {
    session.on(SirdataApiEvent.eventNotFound, () => {
        history.replace(pathError404);
    });

    return (
        <React.StrictMode>
            <Suspense fallback={<></>}>
                <Router history={history}>
                    <ScrollToTop/>
                    <Switch>
                        <Route exact path={pathSso} component={Sso}/>

                        <Route exact path={pathAccess} component={Access}/>
                        <Route exact path={pathError403} component={Error403}/>
                        <Route exact path={pathError404} component={Error404}/>
                        <Route exact path={pathError500} component={Error500}/>

                        <Route exact path="/" component={() => <Redirect to={pathLogin}/>}/>
                        <Route exact path={pathLogin} component={Login}/>

                        <PrivateRoute path={pathHome} component={Home} requiredAuthorization={undefined}/>
                        <PrivateRoute path={pathContainerCreate} component={ContainerCreate} requiredAuthorization={undefined}/>
                        <PrivateRoute path={pathContainerDetails} component={ContainerDetails} requiredAuthorization={undefined}/>
                        <PrivateRoute path={pathContainerList} component={ContainerList} requiredAuthorization={undefined}/>

                        <Route exact path={pathLegalNotice} component={LegalNotice}/>
                        <Route exact path={pathTermsAndConditions} component={TermsAndConditions}/>

                        <Route component={() => <Redirect to={pathError404}/>}/>
                    </Switch>
                </Router>
            </Suspense>
        </React.StrictMode>
    );
}

export default App;
