import {PortalConfig} from "../common/api/PortalConfig";
import {Origin} from "../common/api/model/Origin";
import {MENU} from "./Menu";
import {HeaderModule} from "../common/utils/HeaderModule";
import {pathHome, sirdataDataPrivacyPolicyUrl} from "../common/utils/constants";
// import {pathTermsAndConditions} from "./constants";

export const PORTAL = new PortalConfig(Origin.GTM_SERVER_SIDE, pathHome)
    .withMenuItems(MENU)
    .withHeaderModules([HeaderModule.CONTACT])
    .withFooter({
        legalNotice: true,
        cookiesSettings: true,
        items: [
            // {label: "terms_and_conditions", path: pathTermsAndConditions},
            {label: "privacy_policy", path: sirdataDataPrivacyPolicyUrl}
        ]
    });
